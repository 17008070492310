module.exports = [{
      plugin: require('/home/deploy/dev/arw-story/node_modules/gatsby-plugin-google-gtag/gatsby-browser'),
      options: {"plugins":[],"trackingIds":["UA-101088202-1"],"pluginConfig":{"exclude":[]}},
    },{
      plugin: require('/home/deploy/dev/arw-story/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"typekit":{"id":"fmv2wqs"},"google":{"families":["Noto Sans TC","Noto Sans SC"]}},
    },{
      plugin: require('/home/deploy/dev/arw-story/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
